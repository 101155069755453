<template>
  <div>
    <Modal v-model="show" width="1000" :mask-closable="false">
        <div slot="header">
            <h4 class="modal-title">消息详情</h4>
            <h1 class="content-title">{{contentObj.title}}</h1>
            <span class="content-time">{{contentObj.sendTime}}</span>
        </div>
        <div class="content-box" v-html="contentObj.content">
        </div>
        <div slot="footer"></div>
    </Modal>
  </div>
</template>

<script>
export default {
    // props: ['modalShow'],
    props: {
        modalShow: {
            type: Boolean,
            default: false
        },
        contentObj: {
            type: Object,
            required: true,
        }
    },
    computed: {
        show: {
            get() {
                return this.modalShow
            },
            set() {
                this.$emit("closeModal")
            }
        },
    },
    data() {
        return {
        }
    },
    methods: {
    },
}
</script>

<style lang="scss" scoped>
::v-deep .ivu-modal-content {
    padding: 40px;
    border-radius: 18px;
    overflow: hidden;
    .ivu-modal-close {
        width: 50px;
        height: 50px;
        line-height: 50px;
        text-align: center;
        background: #00A4FF;
        border-radius: 0px 0px 0px 18px;
        top: 0;
        right: 0;
        .ivu-icon {
            top: 5px;
            color: #fff;
            font-size: 32px;
        }
    }
    .ivu-modal-header {
        position: relative;
        padding: 0;
        .modal-title {
            font-size: 24px;
            font-family: Source Han Sans SC;
            font-weight: 400;
            color: #333333;
        }
        .content-title {
            font-size: 28px;
            font-family: Source Han Sans SC;
            font-weight: 500;
            color: #333333;
            margin: 30px 0 15px;
        }
        .content-time {
            position: absolute;
            right: 0;
            bottom: 17px;
        }
    }
    .ivu-modal-body {
        padding: 0;
        margin-top: 19px;
        .content-box {
            max-height: 405px;
            overflow-y:auto
        }
    }
    .ivu-modal-footer {
        padding: 0;
        border-top: none;
    }
}
</style>