<template>
  <div id="system">
    <TopList :navList="navList" @setType="setType" :type="type"></TopList>
    <Msg></Msg>
  </div>
</template>

<script>
import TopList from "../../components/TopList";
import Msg from "./components/msg";
export default {
  name: "index",
  components: {
    TopList,
    Msg,
  },
  methods: {
    setType(e) {
      this.type = e;
      this.name = this.navList[e];
    },
  },
  data() {
    return {
      type: 0,
      name: "",
      navList: ["通知"],
    };
  },
};
</script>

<style lang="scss" scoped>
#system {
  height: 948px;
  background: #ffffff;
  border: 1px solid #999999;
  border-radius: 10px;
}
</style>
