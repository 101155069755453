<template>
  <div id="msg">
    <ul>
      <li
        v-for="(item, i) in list"
        :key="item.id"
        :class="i === list.length - 1 ? '' : 'list'"
        @click="openModal(item)"
      >
        <p style="cursor: pointer">{{ item.title }}</p>
        <p class="time">{{ item.sendTime }}</p>
      </li>
    </ul>
    <user-modal :contentObj="contntObj" :modalShow="modalShow" @closeModal="modalShow=false"/>
    <div class="no-results" v-show="total === 0 && !spinShow">
      <img :src="require('@/assets/qietu/dynamic.png')" alt="">
      <p>暂无通知</p>
    </div>
    <Paging
      :total="total"
      :curPage="page"
      :limit="12"
      @changePager="handlePager"
    ></Paging>
    <Spin size="large" fix v-show="spinShow"></Spin>
  </div>
</template>

<script>
import Paging from "@/components/paging/index.vue";
import userModal from "./modal"
import { systemList } from "../../../../../apis/user";

export default {
  name: "msg",
  components: {
    Paging,
    userModal
  },
  data() {
    return {
      total: 0,
      page: 1,
      list: [],
      modalShow: false,
      contntObj: {},
      spinShow: false
    };
  },
  created() {
    this.getData();
  },
  methods: {
    openModal(obj) {
      this.contntObj = obj
      this.modalShow = true
    },
    handlePager(page) {
      this.page = page
      this.getData()
    }, 
    async getData() {
      let obj = {
        pageSize: 12,
        pageNo: this.page,
      };
      this.spinShow = true
      let res = await systemList(obj);
      this.total = +res.data.total;
      this.list = res.data.list;
      this.spinShow = false
    },
  },
};
</script>

<style lang="scss" scoped>
#msg {
  position: relative;
  ul {
    margin-left: 44px;
    margin-right: 51px;

    .list {
      border-bottom: 1px #a0a0a0 dotted;
    }

    li {
      width: 100%;
      //background: yellow;
      height: 60px;
      display: flex;
      justify-content: space-between;
      //background: pink;
      .time {
        text-align: right;
        color: #999999;
      }

      p {
        width: 564px;
        height: 60px;
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #333333;
        line-height: 60px;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
      }
    }
  }
  .no-results {
    margin: 0 auto;
    width: 500px;
    // height: 300px;
    img {
      width: 100%;
    }
    p {
      text-align: center;
      font-size: 16px;
      color: #999999;
    }
  }
}
</style>
